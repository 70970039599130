<template>
  <full-page>
    <tool-bar>
      <template v-slot:title>
        <p class="text-lg mr-5">{{ $route.meta.name }}</p>
      </template>
      <template v-slot:actions>
        <!-- <label
          for="create-btn"
          class="relative btn-tools-with-icon-disabled whitespace-nowrap"
          :class="{
            'btn-tools-with-icon': $store.getters['auth/isAdmin']
          }"
        >
          <i class="mdi mdi-plus btn-tools-with-icon-icon" />
          <p class="">
            <router-link to="/devices/create">Aggiungi</router-link>
          </p>
        </label> -->
      </template>
      <template v-slot:actionButtons>
        <router-link to="/pages/create">
          <button class="btn-tools btn-with-icons">
            <i class="mdi mdi-plus " />
            Aggiungi
          </button>
        </router-link>
        <input type="checkbox" class="hidden" id="create-btn" />
        <label
          for="delete-btn"
          class="relative py-2  btn-tools-with-icon-disabled flex space-x-2 whitespace-nowrap"
        >
          <i class="mdi mdi-delete "></i>

          <p class="">Elimina</p>
        </label>
        <input type="checkbox" class="hidden" id="delete-btn" />
      </template>
    </tool-bar>
    <div
      class="fixed left-0 right-0 top-24 bottom-0 overscroll-y-auto overflow-y-auto"
    >
      <table-data
        :data="pages"
        :options="tableOpts"
        :selectable="false"
        v-if="pages"
        :key="pages.length"
      >
        <template v-slot:th-column-value="props">
          <span v-if="props.label == 'id'" class="hidden"></span>
          <span v-else>{{ props.label }}</span>
        </template>

        <template v-slot:column-value="props">
          <span v-if="props.field == 'id'" class="hidden"></span>
          <span v-else class="cursor-pointer">
            {{ props.column }}
          </span>
        </template>

        <template v-slot:actions="props">
          <span>
            <button
              class="focus:outline-none mx-2"
              @click="$router.push(`/pages/${props.row.id}`)"
            >
              <i
                class="mdi mdi-pencil w-3 h-3 text-gray-600 hover:text-blue-500"
              />
            </button>
            <button v-if="auth" class="focus:outline-none mx-2">
              <i
                class="mdi mdi-trash-can w-3 h-3 text-gray-600 hover:text-red-500"
              />
            </button>
          </span>
        </template>
      </table-data>
    </div>
  </full-page>
</template>

<script>
import { mapGetters } from 'vuex'
import PagesJson from '@/entities/Pages/PagesListJson'
export default {
  name: 'PagesList',
  components: {
    FullPage: () => import('@/_templates/FullPage.vue'),
    ToolBar: () => import('@/_components/ToolBar.vue'),
    TableData: () => import('@/_components/TableData.vue')
  },
  data: function() {
    return {
      tableOpts: {
        keys: ['id', 'name', 'slug', 'title', 'ctaUrl']
      }
    }
  },
  computed: {
    ...mapGetters('auth', ['isAdmin']),
    pages() {
      return PagesJson
    }
  }
}
</script>

<style></style>
