<template>
  <div data-app>
    <LeftMenuPage
      :menu="[
        { title: 'add', route: 'add-page', icon: 'mdi-plus' },
        { title: 'edit', route: 'page', icon: '' }
      ]"
      :titleMenu="'Pagine'"
      titleContent="'Tutte le pagine'"
    >
      <ToolBar>
        <template v-slot:title>
          <span v-if="isEdit">Modifica</span>
          <span v-else>Crea</span>
        </template>
        <template v-slot:actions>
          <label
            for="create-btn"
            class="relative btn-tools-with-icon-disabled whitespace-nowrap"
            :class="{
              'btn-tools-with-icon': $store.getters['auth/isAdmin']
            }"
          >
            <router-link to="/pages/list" class="flex items-center gap-4"
              ><i class="mdi mdi-chevron-left text-lg"></i> Tutte le
              pagine</router-link
            >
          </label>
          <input type="checkbox" class="hidden" id="back-btn" />
        </template>
      </ToolBar>
      <div
        class="fixed overscroll-y-auto overflow-y-auto left-0 md:left-64 right-0 top-24 bottom-0 py-5"
      >
        <on-form :title="pageTitle" :subtitle="pageSubtitle" class="w-full">
          <template v-slot:content>
            <section class="my-4 mb-12">
              <h1 class="text-xl">Seleziona il layout</h1>
              <div
                @click="toggleLayoutActive"
                class="max-w-xs bg-gray-200 p-4 mt-5  rounded-md cursor-pointer"
                :class="layoutActive ? 'bg-primary-400' : ''"
              >
                <div class="h-40 bg-white w-full">
                  <div class="grid grid-cols-2 h-full py-4 px-1 gap-3">
                    <div
                      class="col-span-1 bg-gray-300 h-full items-center justify-center flex"
                    >
                      <i class="mdi mdi-file-image-outline"></i>
                    </div>
                    <div class="col-span-1 mt-5">
                      <div class="bg-gray-600 w-12 h-2 rounded-sm"></div>
                      <div class="bg-gray-400 w-16 h-1 rounded-sm mt-1"></div>
                      <div class="bg-gray-200 w-12 h-1 rounded-sm mt-5"></div>
                      <div class="bg-gray-200 w-14 h-1 rounded-sm mt-1"></div>
                      <div class="bg-gray-200 w-12 h-1 rounded-sm mt-1"></div>
                      <div class="bg-gray-200 w-10 h-1 rounded-sm mt-1"></div>
                      <div class="grid grid-cols-5 mt-7">
                        <div class="col-start-1 col-span-1 text-right">
                          <div class="bg-blue-500 h-2 rounded-md"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div class="grid grid-cols-7 gap-4 align-baseline items-baseline">
              <div class="col-span-2">
                <h1>Nome</h1>
                <p class="text-gray-500 text-xs">
                  Nome della pagina
                </p>
              </div>
              <div class="col-span-3">
                <on-input v-model="formData.name"> </on-input>
              </div>
              <div class="col-span-1">Slug</div>
              <div class="col-span-1">
                <on-input
                  v-model="formData.slug"
                  classes="opacity-50 cursor-not-allowed"
                ></on-input>
              </div>
              <div class="col-span-2">
                <h1>Titolo</h1>
                <p class="text-gray-500 text-xs">
                  Titolo presente nella pagina
                </p>
              </div>
              <div class="col-span-5">
                <on-input v-model="formData.title"> </on-input>
              </div>
              <div class="col-span-2">
                <h1>Sottotitolo</h1>
                <p class="text-gray-500 text-xs">
                  Sottotitolo presente nella pagina
                </p>
              </div>
              <div class="col-span-5">
                <on-input v-model="formData.subtitle"> </on-input>
              </div>
              <div class="col-span-2">Descrizione</div>
              <div class="col-span-5">
                <quill-editor
                  v-model="formData.description"
                  :options="editorOptions"
                ></quill-editor>
              </div>
              <div class="col-span-2">Immagini</div>
              <div class="col-span-5">
                <div class="flex gap-5 items-center">
                  <v-file-input
                    label="immagini"
                    accept="image/png, image/jpeg, image/bmp"
                    truncate-length="15"
                    multiple
                    small-chips
                  ></v-file-input>
                  <v-btn>libreria immagini</v-btn>
                </div>

                <div class="grid grid-cols-5 gap-4">
                  <v-card
                    v-for="(image, i) in formData.images"
                    :key="i"
                    max-height="150"
                  >
                    <v-img
                      :src="`${image}`"
                      class="w-full h-full object-cover"
                    ></v-img>
                    <v-btn
                      fab
                      icon
                      absolute
                      class="top-0 right-0 mx-auto"
                      small
                      color="error"
                      ><v-icon>close</v-icon></v-btn
                    >
                  </v-card>
                </div>
              </div>
              <div class="col-span-2">
                <h1>Call to action label</h1>
                <p class="text-gray-500 text-xs">
                  Label del pulsante
                </p>
              </div>
              <div class="col-span-5">
                <on-input v-model="formData.ctaLabel"> </on-input>
              </div>
              <div class="col-span-2">
                <h1>Call to action URL</h1>
                <p class="text-gray-500 text-xs">
                  URL del pulsante
                </p>
              </div>
              <div class="col-span-5">
                <on-input v-model="formData.ctaUrl"> </on-input>
              </div>
            </div>
          </template>
          <template v-slot:action>
            <div class="col-span-5 col-start-3 gap-4">
              <v-btn color="primary" outlined>{{ buttonText }}</v-btn>
              <!-- <span v-if="crudError" class="text-red-500 text-sm">{{
                crudError
              }}</span> -->
            </div>
          </template>
        </on-form>
      </div>
    </LeftMenuPage>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import OnForm from '@/_components/_ui/OnForm.vue'
import OnInput from '@/_components/_ui/OnInput.vue'
import PagesJson from '@/entities/Pages/PagesListJson'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
export default {
  name: 'PageEdit',
  components: {
    LeftMenuPage: () => import('@/_templates/LeftMenuPage.vue'),
    ToolBar: () => import('@/_components/ToolBar.vue'),
    OnInput,
    OnForm,
    quillEditor
  },
  data() {
    return {
      layoutActive: false,
      formData: {
        id: null,
        name: null,
        slug: null,
        title: null,
        subtitle: null,
        content: null,
        images: [],
        ctaLabel: null,
        ctaUrl: null
      },
      editorOptions: {
        modules: {
          toolbar: [
            [{ header: [false, 1] }],
            ['bold', 'italic', 'underline'],
            [{ header: 1 }, { header: 2 }, { header: 3 }]
          ]
        }
      },
      isEdit: false
    }
  },
  computed: {
    ...mapGetters('auth', ['isAdmin']),
    pageTitle() {
      return this.$route.params.id ? 'Modifica la pagina' : 'Crea la pagina'
    },
    pageSubtitle() {
      return this.$route.params.id
        ? ''
        : 'Segui le istruzioni per creare la pagina'
    }
  },
  created: function() {
    try {
      if (this.$route.params.id) {
        let pageData = PagesJson.filter(obj => {
          return obj.id == this.$route.params.id
        })
        console.log('Struct ', pageData)

        this.buttonText = 'Salva modifiche'
        this.formData = pageData[0]
        this.isEdit = true
        this.loading = false
      } else {
        this.buttonText = 'Crea'
        this.loading = false
      }
    } catch (error) {
      console.log('Errore caricamento struttura', error)
    }
  },
  methods: {
    toggleLayoutActive() {
      this.layoutActive = !this.layoutActive
    }
  }
}
</script>

<style></style>
