<template>
  <div class="grid" :class="inline ? 'grid-flow-col' : 'grid-flow-row'">
    <slot name="before"></slot>
    <span v-if="label" :class="inline ? 'col-span-4 md:col-span-3' : ''">{{
      label
    }}</span>
    <div class="flex flex-col items-center justify-center">
      <input
        :type="inputType"
        @input="onInput"
        :placeholder="placeholder"
        :class="classes"
        v-model="newVal"
      />

      <slot name="error"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OnInput',
  props: {
    label: String,
    inputType: {
      type: String,
      default: 'text'
    },
    value: {
      type: String
    },
    placeholder: {
      type: String
    },
    classes: {
      type: [Array, Object, String]
    },
    inline: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      newVal: this.value
    }
  },
  watch: {
    value(value) {
      this.newVal = value
    },
    newVal: {
      immediate: true,
      handler(n) {
        this.$emit('input', n)
      }
    }
  },
  methods: {
    onInput(event) {
      this.newVal = event.target.value
    }
  }
}
</script>

<style lang="postcss" scoped>
input {
  color: inherit;
  font-size: inherit;
  @apply p-2 rounded-md pl-4 bg-gray-100;
}
input.error {
  @apply bg-red-100;
}
input.error::placeholder {
  @apply text-red-600;
}
input:focus {
  @apply shadow-inner;
}
</style>
