<template>
  <full-page>
    <tool-bar>
      <template v-slot:title>
        <p class="text-lg mr-5">{{ $route.meta.name }}</p>
      </template>
      <template v-slot:actions> </template>
      <template v-slot:actionButtons>
        <router-link to="/media/create">
          <button class="btn-tools btn-with-icons">
            <i class="mdi mdi-plus " />
            Aggiungi
          </button>
        </router-link>
        <input type="checkbox" class="hidden" id="create-btn" />
        <label
          for="delete-btn"
          class="relative py-2  btn-tools-with-icon-disabled flex space-x-2 whitespace-nowrap"
        >
          <i class="mdi mdi-delete "></i>

          <p class="">Elimina</p>
        </label>
        <input type="checkbox" class="hidden" id="delete-btn" />
      </template>
    </tool-bar>
    <div
      class="fixed left-0 right-0 top-24 bottom-0 overscroll-y-auto overflow-y-auto"
    >
      <table-data
        :data="imageList"
        :options="tableOpts"
        :key="updatingKey"
        @rowSelected="goToEdit"
        :showPagination="showPagination && imageList.length > 0 ? true : false"
        @nextClickHandler="goToNextPage"
        sortable
        defaultSortField="title"
        defaultSortDir="desc"
      >
        <template v-slot:th-column-value="props">
          <span v-if="props.label == 'id'" class="hidden"></span>
          <span v-else>{{ props.label }}</span>
        </template>

        <template v-slot:column-value="props">
          <span v-if="props.field == 'id'" class="hidden"></span>
          <span v-else-if="props.field == 'path'">
            <img
              :src="props.column"
              class="object-cover w-16 h-16 rounded-md"
            />
          </span>
          <span v-else class="cursor-pointer">
            {{ props.column }}
          </span>
        </template>

        <template v-slot:actions="props">
          <span>
            <button
              class="focus:outline-none mx-2"
              @click.stop="$router.push(`/media/${props.row.id}`)"
            >
              <i
                class="mdi mdi-pencil w-3 h-3 text-gray-600 hover:text-blue-500"
              />
            </button>
            <!-- <button v-if="auth" class="focus:outline-none mx-2">
              <i
                class="mdi mdi-trash-can w-3 h-3 text-gray-600 hover:text-red-500"
              />
            </button> -->
          </span>
        </template>
      </table-data>
    </div>
  </full-page>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'MediaList',
  components: {
    FullPage: () => import('@/_templates/FullPage.vue'),
    ToolBar: () => import('@/_components/ToolBar.vue'),
    TableData: () => import('@/_components/TableData.vue')
    // OnImageLoader: () => import('@/_components/_ui/OnImageLoader.vue'),
    // OnButton: () => import('@/_components/_ui/OnButton.vue')
  },
  data: function() {
    return {
      tableOpts: {
        keys: ['id', 'title', 'description', 'path'],
        sortableKeys: ['title', 'description']
      },
      ASSET_DIR: process.env.ASSETS_DIR,
      imageList: [],
      confirmDelete: false,
      updatingKey: 0,
      nextToken: null,
      showPagination: true
    }
  },
  computed: {
    ...mapGetters({ auth: 'auth/isAdmin' })
  },
  methods: {
    async loadImages() {
      let images = await this.$store.dispatch('media/list', {
        limit: 20,
        nextToken: this.nextToken
      })

      return images
    },
    async goToNextPage() {
      let data = await this.loadImages()
      let newList = data.items.map(el => ({
        ...el,
        path: `${this.ASSET_DIR}thumb-${el.title}`
      }))
      this.imageList = [...this.imageList, ...newList]
      this.nextToken = data.nextToken
      this.showPagination = data.nextToken !== null
      this.updatingKey = this.updatingKey + 1
    },

    goToEdit(id) {
      this.$router.push(`/media/${id}`)
    }

    // async deleteImage(img) {
    //   console.log('Delete Image', img)
    //   debugger
    //   if (!img) {
    //     this.deleteId = null
    //     this.confirmDelete = false
    //     return
    //   }
    //   await this.$store.dispatch('media/delete', { id: img.id })
    //   let images = await this.$store.dispatch('media/list', {})
    //   this.imageList = images.items.map(el => ({
    //     ...el,
    //     path: `${this.ASSET_DIR}thumb-${el.title}`
    //   }))
    //   console.log(this.imageList)
    //   this.confirmDelete = true
    // }
  },
  async created() {
    let data = await this.loadImages()

    this.imageList = data.items.map(el => ({
      ...el,
      path: `${this.ASSET_DIR}thumb-${el.title}`
    }))
    this.nextToken = data.nextToken
    this.updatingKey = this.updatingKey + 1
  }
}
</script>

<style></style>
