<template>
  <v-dialog
    v-model="value"
    transition="dialog-bottom-transition"
    max-width="600"
    @click:outside="$emit('close', true)"
  >
    <v-card>
      <v-card-title class="headline"> {{ title }} </v-card-title>
      <v-card-subtitle v-if="structure.title">{{
        structure.title
      }}</v-card-subtitle>

      <v-card-text>
        <v-text-field label="Titolo" v-model="label"></v-text-field>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="" text @click="$emit('close', true)"> annulla </v-btn>

        <v-btn color="primary" text @click="addTag"> salva </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    structure: {
      type: Object,
      default: () => ({})
    },
    title: {
      type: String,
      default: 'Crea nuovo Tag'
    }
  },
  data() {
    return {
      label: ''
    }
  },
  methods: {
    async addTag() {
      try {
        let tag = await this.$store.dispatch('tags/create', {
          label: this.label
        })
        console.log('New tag created', tag)
        if (tag.id) {
          this.label = ''
          this.$store.dispatch('tags/list')
          this.$emit('added', tag)
          this.$emit('close', true)
        }
        // if (this.structure.id) {
        //   this.$store.dispatch("tags/createTagStructure", {
        // 		tagStructuresStructureId:this.structure.id,
        //     label: this.label
        //   })
        // }
      } catch (error) {
        console.log('Error while adding new Tag ', error)
      }
    }
  }
}
</script>

<style></style>
