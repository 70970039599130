<template>
  <div data-app>
    <LeftMenuPage
      :menu="[
        { title: 'add', route: 'add-page', icon: 'mdi-plus' },
        { title: 'edit', route: 'page', icon: '' }
      ]"
      :titleMenu="'Media'"
      titleContent="'Tutti i media"
    >
      <ToolBar>
        <template v-slot:title>
          <span>{{ pageTitle }}</span>
        </template>
        <template v-slot:actions>
          <label
            for="create-btn"
            class="relative btn-tools-with-icon-disabled whitespace-nowrap"
            :class="{
              'btn-tools-with-icon': $store.getters['auth/isAdmin']
            }"
          >
            <router-link to="/media/list" class="flex items-center gap-4"
              ><i class="mdi mdi-chevron-left text-lg"></i> Tutte i
              media</router-link
            >
          </label>
          <input type="checkbox" class="hidden" id="back-btn" />
        </template>
      </ToolBar>
      <div
        class="fixed overscroll-y-auto overflow-y-auto left-0 md:left-64 right-0 top-24 bottom-0 py-5"
      >
        <tags-modal
          v-model="showTagModal"
          @close="closeTagModal"
          @added="addTagToForm"
          :image="formData"
        ></tags-modal>
        <on-form
          v-if="!isEdit"
          :title="formTitle"
          :subtitle="formSubtitle"
          class="w-full"
        >
          <template v-slot:content>
            <div class="grid grid-cols-7 gap-4 align-baseline items-baseline">
              <div class="grid grid-cols-7 items-center col-span-7">
                <div class="col-span-2">
                  Immagine
                </div>
                <div class="col-span-5">
                  <v-card max-height="300">
                    <on-image-loader
                      class="col-span-4 md:col-span-3"
                      @uploadCompleted="uploadCompleted"
                    ></on-image-loader>
                  </v-card>
                </div>
              </div>
            </div>
          </template>
        </on-form>

        <on-form
          v-else
          :title="formTitle"
          :subtitle="formSubtitle"
          class="w-full"
        >
          <template v-slot:content>
            <div class="grid grid-cols-7 gap-4 align-baseline items-baseline">
              <div class="grid grid-cols-7 items-center col-span-7">
                <div class="col-span-2">
                  Immagine
                </div>
                <div class="col-span-5">
                  <v-card max-height="300">
                    <img
                      :src="`${ASSET_DIR}thumb-${formData.title}`"
                      class="w-full h-72 object-cover"
                    />
                  </v-card>
                </div>
              </div>

              <div class="col-span-2">
                <h1>Descrizione</h1>
                <p class="text-gray-500 text-xs">
                  Descrizione dell'immagine
                </p>
              </div>
              <div class="col-span-5">
                <on-input v-model="formData.description"> </on-input>
              </div>
              <div class="col-span-2">
                <h1>Strutture associate</h1>
                <p class="text-gray-500 text-xs">
                  Lista strutture dove questo media è presente
                </p>
              </div>
              <div class="col-span-5">
                <v-select
                  v-model="formData.structures"
                  multiple
                  chips
                  :items="structures"
                  item-text="title"
                  return-object
                  @change="updateSelectedStrucures"
                  @blur="updateImageToStructures"
                >
                </v-select>
              </div>
              <div class="col-span-2">Tags</div>
              <div class="col-span-5">
                <div class="w-full">
                  <ul class="my-4 px-0 mx-0" v-if="!!formData.tags.items">
                    <v-chip
                      close
                      v-for="(item, i) in formData.tags.items"
                      :key="i"
                      tag="li"
                      @click:close="removeTag(item.id)"
                      class="mr-4 mb-2"
                      >{{ item.tag.label }}</v-chip
                    >
                  </ul>
                  <ul class="my-4 px-0 mx-0" v-if="!!ghostTags">
                    <v-chip
                      close
                      v-for="(item, i) in ghostTags"
                      :key="i"
                      tag="li"
                      @click:close="removeGhostTag(item.id)"
                      class="mr-4 mb-2"
                      >{{ item.label }}</v-chip
                    >
                  </ul>

                  <v-divider></v-divider>
                  <h2 class="text-xs text-gray-400 mt-4">Tag recenti</h2>
                  <ul class="my-4 px-0 mx-0">
                    <v-chip
                      v-for="(item, i) in tagsData"
                      :key="i"
                      tag="li"
                      @click="addTagToForm(item)"
                      class="mr-4 mb-2"
                      >{{ item.label }}</v-chip
                    >
                    <v-chip
                      tag="li"
                      class="mr-4 mb-2"
                      color="primary"
                      outlined
                      @click="addNewTag"
                      ><v-icon>add</v-icon> Nuovo Tag</v-chip
                    >
                  </ul>
                </div>
              </div>
            </div>
          </template>
          <template v-slot:action>
            <div class="col-span-5 col-start-3 gap-4" v-if="isEdit">
              <v-btn
                color="primary"
                outlined
                @click="submitForm"
                :disabled="$v.$invalid"
                :loading="$store.state.media.loading"
                >{{ buttonText }}</v-btn
              >
              <!-- <span v-if="crudError" class="text-red-500 text-sm">{{
                crudError
              }}</span> -->
            </div>
          </template>
        </on-form>
      </div>
    </LeftMenuPage>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import OnForm from '@/_components/_ui/OnForm.vue'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import OnInput from '@/_components/_ui/OnInput.vue'
import TagsModal from '@/entities/Tags/TagsModal.vue'
import { v4 as uuid } from 'uuid'
import Storage from '@aws-amplify/storage'

export default {
  components: {
    OnForm,
    LeftMenuPage: () => import('@/_templates/LeftMenuPage.vue'),
    ToolBar: () => import('@/_components/ToolBar.vue'),
    TagsModal,
    OnInput,
    OnImageLoader: () => import('@/_components/_ui/OnImageLoader.vue')
  },
  mixins: [validationMixin],
  data: () => ({
    isEdit: false,
    pageTitle: '',
    formData: {
      id: null,
      description: null,
      structures: [],
      tags: []
    },
    buttonText: '',
    crudError: null,
    ghostTags: [],
    allTags: [],
    selectedStructures: [],
    uptdateCompleted: true,
    ASSET_DIR: process.env.ASSETS_DIR,
    showTagModal: false,
    oldValues: null,
    newImgId: null
  }),
  validations: {
    formData: {
      title: { required }
    }
  },
  computed: {
    ...mapGetters('auth', ['isAdmin']),
    ...mapGetters({ tagsData: 'tags/getData' }),
    ...mapGetters({ structures: ['structures/getData'] }),
    formTitle() {
      return this.$route.params.id ? 'Modifica il media' : 'Crea il media'
    },
    formSubtitle() {
      return this.$route.params.id ? '' : 'Carica una immagine nel box indicato'
    }
  },
  watch: {
    $route() {
      this.loadData()
    },
    immediate: true
  },
  async mounted() {
    await this.loadData()
  },
  methods: {
    async loadData() {
      await this.$store.dispatch('structures/list')

      await this.$store.dispatch('tags/list')
      this.formData.tags = { items: [], next: null }
      try {
        if (this.$route.params.id) {
          console.log('Carico il media ', this.$route.params.id)
          let img = await this.$store.dispatch(
            'media/getImage',
            this.$route.params.id
          )
          console.log('Image ', img)
          this.buttonText = 'Salva modifiche'

          this.formData = img
          this.formData.structures = this.formData.structures.items.map(
            struct => {
              return {
                title: struct.structure.title,
                relId: struct.id,
                ...struct
              }
            }
          )
          this.imageOldTitle = this.formData.title
          this.isEdit = true
          this.pageTitle = 'Modifica'
          this.oldValues = this.formData
          this.oldValues = Object.freeze({ ...this.formData })
        } else {
          this.buttonText = 'Crea'
          this.pageTitle = 'Crea'
        }
      } catch (error) {
        console.log('Errore caricamento media', error)
      }
    },
    addNewTag() {
      this.showTagModal = true
    },
    closeTagModal() {
      // this.getAllTags();
      this.showTagModal = false
      console.log('Close tag modal', this.allTags)
    },
    uploadCompleted: async function(files) {
      console.log(files)
      this.filesLoading = true
      try {
        let imgs = await Promise.all(
          files.map(async file => await this.uploadFileToS3(file))
        )
        console.log('all done!', imgs)

        var intervalIteration = 3
        var interval = setInterval(async () => {
          if (intervalIteration <= 5) {
            intervalIteration++
            let imgAvailable = await this.isImageAvailable(imgs[0])

            if (imgAvailable) {
              clearInterval(interval)
              this.$router.push({
                name: 'mediaEdit',
                params: { id: `${this.newImgId}` }
              })
            }
          } else {
            clearInterval(interval)
          }
        }, 5000)

        // Create the connection between structures and keys
      } catch (error) {
        console.error('Errore durante il caricamento ', error)
      } finally {
        console.log('FATTo')
      }

      // TODO: take (from the state variable) all the keys uploaded and,
      // once the structure has been created, update the structureID of
      // each image, to create the proper connection
    },
    async uploadFileToS3(file) {
      //a function that returns a promise
      let uu = uuid()
      let ext = file.name.split('.')[1]
      let newName = `${uu}.${ext}`
      console.log('Before put', newName, file)

      const { key } = await Storage.put(newName, file, {
        contentType: file.type
      })

      console.log('S3 Object key', key)
      let img = await this.$store.dispatch('media/create', {
        title: key
      })
      console.log('%cCreo le connessioni ', 'color:cyan;font-size:1.2rem', img)

      // //TODO: add key to a state variable to use it later

      this.newImgId = img.data.createImage.id
      return Promise.resolve(key)
    },
    async isImageAvailable(imgStorKey) {
      try {
        let image = await Storage.get(imgStorKey)
        console.log('TEST IMMAGINE', image)
        return true
      } catch (e) {
        console.log('TEST IMMAGINE ERRORE', e)
        return false
      }
    },
    submitForm() {
      try {
        this.updateMedia()
      } catch (e) {
        console.log('Errore salvataggio form: ', e)
      }
    },
    async updateMedia() {
      let data = this.formData
      console.log('Modifico il media ', data)
      await this.$store.dispatch('media/update', {
        updated: {
          id: data.id,
          description: data.description
        }
      })
      console.log('Modifico i tag', data)
      this.$router.push({ name: 'mediaList' })
    },

    updateSelectedStrucures(el) {
      console.log(el)
      this.selectedStructures = el
      this.uptdateCompleted = false
    },
    async updateImageToStructures() {
      console.log('LISTA IMMAGINI DA CARICARE', this.selectedStructures)
      console.log(this.formData.structures)

      this.oldValues.structures.forEach(struct => {
        if (
          !this.selectedStructures.some(el => {
            return el.relId === struct.relId
          })
        ) {
          //struttura disassociata
          this.deleteImageStrucureAss(struct.relId)
        }
      })

      //strutture da associare
      this.selectedStructures.forEach(el => {
        if (el.relId === undefined) {
          this.updateMediaStructure(el)
        }
      })
    },
    async updateMediaStructure(struct) {
      await this.$store.dispatch('media/createImageStructures', {
        imageStructuresImageId: this.formData.id,
        imageStructuresStructureId: struct.id
      })
    },
    async deleteImageStrucureAss(assId) {
      try {
        await this.$store.dispatch('media/deleteImageStructures', assId)
      } catch (error) {
        console.log('Errore di cancellazione ', error)
      }
    },
    async addTagToForm(tag) {
      if (!this.formData.tags.items.some(t => t.tag.id == tag.id)) {
        let newData = await this.$store.dispatch('tags/createTagImage', {
          tagImagesImageId: this.formData.id,
          tagImagesTagId: tag.id
        })
        this.formData.tags.items.push(newData.data.createTagImages)
        console.log('✅', newData)
      } else {
        console.log('❌')
      }
    },
    async removeTag(id) {
      await this.$store.dispatch('tags/deleteTagImage', id)
      let idx = this.formData.tags.items.findIndex(t => t.id == id)
      this.formData.tags.items.splice(idx, 1)
    }
  }
}
</script>

<style></style>
